import { ADD_MESSAGES, MESSAGES_LOADDED, ADD_USER, REMOVE_USER, REMOVE_MESSAGES, NOTIFICATION_LOADED } from "../actions/messages";

const initialState = {messages:0, online_users:[], notifications:0}

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case MESSAGES_LOADDED : 
            return {
                ...state,
                messages:+action.payload.messages,
                online_users:action.payload.online_users
            }
        case ADD_MESSAGES : 
            return {
                ...state,
                messages:state.messages+1
            }
        case ADD_USER : 
            return {
                ...state,
                online_users:[...state.online_users, +action.payload]
            }
        case REMOVE_USER : 
            return {
                ...state,
                online_users:state.online_users?.filter(item=>+item!==+action.payload)
            }
        case REMOVE_MESSAGES : 
            return {
                ...state,
                messages: +state.messages - +action.payload
            }
        case NOTIFICATION_LOADED : 
            return {
                ...state,
                notifications: +action.payload
            }
        default: return state
    }
}

export default reducer