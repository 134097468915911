import {Suspense, lazy} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute';
import { LoadUser } from './application/middlewares/auth';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { Toaster } from 'react-hot-toast';
import BgLoader from './components/BgLoader';
import {io} from "socket.io-client"
import { addMessages, removeUser, addUser } from './application/actions/messages';
import { SocketContext } from './application/comtext/socket';

const Auth = lazy(() => import('./pages/Login/index'));
const Home = lazy(() => import('./pages/index'))

function App() {
  const dispatch = useDispatch();
  const {token, isLoading} = useSelector(state=>state?.auth)
  
  // console.log(token)
  const location = useLocation()
  let socket = {}
  
  socket = io(process.env.REACT_APP_SOCKET_HOST, {
    query: {
      access_token:token,
      admin:true
    },
    reconnection:true
  })
  
  
  useEffect(()=>{
    if(!isLoading && socket ){
      socket.on("connect", ()=>{
        console.log("device was cnnexted")
      })
      socket.on("new_message", (data)=>{
        // console.log("new -mesage")
        if(location.pathname !== "/chats/system-chats"){
          dispatch(addMessages())
        }
      })
      socket.off("user_disconnected").on("user_disconnected", (user_id)=>{
        // console.log("hey event disconnect")
        dispatch(removeUser(user_id));
      })
      socket.off("user_connected").on("user_connected", (user_id)=>{
        // console.log("hey event connect")
        
        dispatch(addUser(user_id));
      })
      socket.emit("get_data", (response)=>{
        // console.log("socket emited")
        // console.log(response, "ssdf nsdf sd")
        // dispatch(messagesLoaded(response))
      })
      
      return function cleanup(){
        socket.disconnect()
        socket.off("new_message", (data)=>{
          if(location.pathname !== "/chat"){
            // dispatch(addMessages())
          }
        })
        socket.off("user_disconnected", (user_id)=>{
          // dispatch(removeUser(user_id));
        })
        socket.off("user_connected", (user_id)=>{
          // dispatch(addUser(user_id));
        })
      }
    }
  }, [isLoading])

  useEffect(() => {
    dispatch(LoadUser());
  }, []);
  if(isLoading){
    return (
      <div>
        loading
      </div>
    )
  }
  return (
    <div className="relative w-full h-screen flex justify-center items-center">
      <SocketContext.Provider value={socket}>
      <Suspense fallback={<BgLoader loading={true}/>}>
        <Routes>
          <Route path="login" element={<Auth />}/>
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<div>Not found</div>}/>
        </Routes>
      </Suspense>
      <Toaster />
      </SocketContext.Provider>
    </div>
  );
}

export default App;
