import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./reducers/auth";
import messages from "./reducers/messages" 

const reducer = combineReducers({
    auth,
    messages
});
 
const store = configureStore({
    reducer,
});
 
export default store;