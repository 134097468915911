import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// @material-tailwind/react
import { ThemeProvider } from "@material-tailwind/react";
import { Provider } from 'react-redux';
import store from './application/index';
import { HashRouter} from 'react-router-dom';
import './translations/i18n'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
      <Provider store = {store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
