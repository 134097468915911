export const MESSAGES_LOADDED = "MESSAGES_LOADDED"
export const messagesLoaded = (data) =>(
    {
        type:MESSAGES_LOADDED,
        payload:data
    } 
)

export const ADD_MESSAGES = "ADD_MESSAGES"
export const addMessages = (data) =>(
    {
        type:ADD_MESSAGES,
        payload:data
    } 
)

export const ADD_USER = "ADD_USER"
export const addUser = (data) =>(
    {
        type:ADD_USER,
        payload:data
    } 
)

export const REMOVE_USER = "REMOVE_USER"
export const removeUser = (data) =>(
    {
        type:REMOVE_USER,
        payload:data
    } 
)

export const REMOVE_MESSAGES = "REMOVE_MESSAGES"
export const removeMessages = (data) =>(
    {
        type:REMOVE_MESSAGES,
        payload:data
    } 
)

export const NOTIFICATION_LOADED = "NOTIFICATION_LOADED"
export const notificationLoaded = (data) =>(
    {
        type:NOTIFICATION_LOADED,
        payload:data
    } 
)


